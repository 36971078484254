/*EVERYWHERE*/
body{font-family: 'Roboto', sans-serif;}
.help, .pointer {cursor: pointer;}
.row{margin-right: 0;margin-left: 0;}
.b{font-weight: bold}
/*.bmd-form-group.is-filled .bmd-label-floating{font-size:1rem !important}*/

/* Rules for sizing the icon. */
.material-icons.md-13 { font-size: 13px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.rating .material-icons{font-size:16px;}
.carousel-control-next,.carousel-control-prev {filter: invert(100%);color:Red}

/*.carousel-indicators{top:0;bottom:unset;width:100%;left:0;margin-left:0}*/
/*.carousel-indicators li{*/
/*    width: 18px;*/
/*    height: 0;*/
/*    margin: 9px;*/
/*    border-radius: 20px;*/
/*    background-clip: border-box;*/
/*}*/
/*.carousel.slide{border:1px solid #6c757d;}*/

/*.spinner {max-width: 150px;margin:5px auto}*/
/*.spinner .btn.btn-up, .spinner .btn.btn-down {margin:2px;padding:0}*/
/*.spinner .form-control {border: 0}*/


/*.h2{font-weight:bold}*/
#itemBox iframe{width:100%}

/*TABLES*/

.table th{padding:15px 5px;}



.navbar{box-shadow:none}


/*HOMEPAGE*/
.nav-pills .nav-link, .nav-tabs .nav-link{padding: .5em;}

/*!*MENU*!*/
/*.dropdown-submenu {position: relative;}*/
/*.dropdown-submenu .dropdown-menu {top: 0;left: 100%;margin-top: -1px;}*/
/*.navbar-light .navbar-toggler{width:100%;}*/
/*.dropdown-item.active, .dropdown-item:active{background-color:#fff;color:#000}*/
/*.navbar-nav .dropdown-menu{background:#f8f9fa}*/

/*.navbar-brand img{width:135px;}*/
/*#mainNav .nav-item:last-child {border-right: none}*/
/*#mainNav .nav-link{text-align:center}*/

/*    !*Mobile*!*/
/*.navbar-toggler{border-radius: 0;}*/



/*@media (min-width: 700px) {*/
/*    !*.mainContent{max-width: 1350px}*!*/
/*    #categoryCarousel{min-height: 310px;}*/
/*}*/

/*!*HEADER*!*/
#logo img{max-height:80px;}
.dropdown-menu .dropdown-item{max-width: 100%;}
.itemsPrice{font-weight:bold}
/*!*AUTOCOMPLETE*!*/
#searchResults {
    background: #ffffff;
    position: absolute;
    top: 75px;
    right: 0;
    z-index: 1007;
    width: 100%;
    max-height:420px;
    overflow:scroll;
    overflow-x:hidden;
}
.searchResult{width:100%;padding:4px;}
.searchResult img{padding-right:5px;}
.searchResult:nth-of-type(odd){background:#ddd}

#topBtns{margin:0 auto}
#searchForm{padding:0 40px;}

#searchResults{border:2px solid #dee2e6}
#searchResults .card-body {padding:3px}
#searchResults .card {
    cursor: pointer;
    margin:2px
}

/*CATEGORIES*/
@media (min-width: 600px) {
    .cat .card{max-width:32%}
}

.dropdown-menu .dropdown-item{padding: 0}
.dropdown-item a, a.dropdown-item{padding: .8rem 1rem !important;}



/*.blockInline{display:inline-block}*/
/*.form-control-borderless {border: none;}*/
/*.form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {border: none;outline: none;box-shadow: none;}*/


/*@media (max-width: 650px) {*/
/*    .filterBar,.filterBar>div{width:100%; margin:3px 0;}*/
/*}*/


.filterBar .dropdown-menu{min-width:235px}

/*!*BASKET*!*/
.checkbox label, .radio label, .switch label, label.checkbox-inline, label.radio-inline, .bmd-form-group .radio label, .bmd-form-group label{color:black}

/*!*BUTTONS*!*/

#basketB {position: fixed;right: 57px;bottom: 40px;display: none;background-color: hsla(0, 0%, 82%, 0.7);height:43px;border:1px solid #a9a9a9;padding:10px 10px 0 10px;font-weight:bold}
#basketB span{margin:13px;}
#basket.hasItems{padding: 12px 5px 15px 5px;}

/*.btn{border-radius: 0;}*/

/*.square, .square .btn, .nav-tabs .nav-item a{border-radius:0;}*/
#go-top {position: fixed;right: 10px;bottom: 40px;display: none; background-color: hsla(0, 0%, 82%, 0.7);width:43px;height:43px;border:1px solid #a9a9a9;padding:10px;}


/*!*ITEMS*!*/

.itemBox .card-title a {font-size:1.15em;color:#000}
.breadcrumb-item.btn-group{margin:0}
.itemBox .card-body{min-height: 125px;padding:0}
.itemBox .card-buttons{min-height: 116px;}

/*.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto{padding:0}*/

/*.close{font-size: 1.2rem;font-weight: 500;line-height: 1.5;}*/
/*!*BLOG ENTRIES*!*/
/*.blogEntries .col-lg-4 {*/
/*    margin-bottom: 1.5rem;*/
/*    text-align: center;*/
/*}*/
/*.blogEntries h2 {*/
/*    font-weight: 400;*/
/*}*/
/*.blogEntries .col-lg-4 p {*/
/*    margin-right: .75rem;*/
/*    margin-left: .75rem;*/
/*}*/
/*.featurette-divider {*/
/*    margin: 3rem 0; !* Space out the Bootstrap <hr> more *!*/
/*}*/

/*.featurette-heading {*/
/*    font-weight: 300;*/
/*    line-height: 1;*/
/*    letter-spacing: -.05rem;*/
/*}*/
/*@media (min-width: 40em) {*/
/*    .featurette-heading {*/
/*        font-size: 50px;*/
/*    }*/
/*}*/
/*@media (min-width: 62em) {*/
/*    .featurette-heading {*/
/*        margin-top: 3rem;*/
/*    }*/
/*}*/
@media (max-width: 500px) {
    .card-footer {
        padding: 0.5rem 0.5rem;
    }
    .breadcrumb{font-size:80%}
}

/*!*FOOTER*!*/
/*footer .col-sm{border-right:1px solid #ececec;padding:5px;margin-bottom:10px}*/

#categoriesList .card{width:150px;display: inline-block;}
#categoriesList .card-body{height:73px}

@media (max-width: 760px) {
    /*    .breadcrumb-item+.breadcrumb-item, .breadcrumb-item+.breadcrumb-item::before, .breadcrumb{padding-left: .1rem;padding-right: .1rem;}*/
    #searchResults {top: 0;position: relative;}
    .container{padding:0;margin:0;max-width:100%}
    .card-body{padding:0.75em}
    body{margin-top:5px;}
    .itemBox .card-body{min-height:190px}
    /*    .row{margin:2px;padding: 0}*/
    /*    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{padding:0}*/
}

.h1, h1{font-size: 2rem;padding:5px}
.h2, h2{font-size: 1.75rem;}
.h3, h3{font-size: 1.5rem;}
.h4, h4{font-size: 1.2rem;}
.card h2{font-size:1.5em}

.checkbox label .checkbox-decorator, label.checkbox-inline .checkbox-decorator{margin-left:10px;padding: 5px;}

.lastVisitedItem{width: 6rem;display: inline-block}
.lastVisitedItem .material-icons{font-size:30px}

#menu .nav-item{text-align:center}
.closeVisited{position:absolute;top:0;right:0; float:right;padding:.25rem}

.alert-info{background-color: #edf8fe;color:#03a9f4}